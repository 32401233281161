const i18n = require('../../config/i18n')

const linkResolver = (doc) => {
  let prefix
  if (i18n.hasOwnProperty(doc.lang)) {
    prefix = i18n[doc.lang].default ? `/` : `${i18n[doc.lang].path}/`
    if (doc.type === 'resource') {
      return `${prefix}resource/${doc.uid}`
    }
    if (doc.type === 'resources') {
      return `${prefix}resources`
    }
    if (doc.type === 'home') {
      return `${prefix}`
    }
    if (doc.type === 'about') {
      return `${prefix}about-us`
    }
    if (doc.type === 'solutions') {
      return `${prefix}solutions`
    }
    if (doc.type === 'meeting') {
      return `${prefix}request-meeting`
    }
    if (doc.type === 'contact') {
      return `${prefix}contact-support`
    }
    if (doc.type === 'imprint') {
      return `${prefix}imprint`
    }
    if (doc.type === 'privacy') {
      return `${prefix}privacy-policy`
    }
    if (doc.type === 'industry') {
      return `${prefix}industry/${doc.uid}`
    }
    if (doc.type === 'services') {
      return `${prefix}services`
    }
    if (doc.type === 'partner') {
      return `${prefix}partner`
    }
  }
  return '/'
}

module.exports = linkResolver
